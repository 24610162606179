<template>
    <div class="topbar">
        <div class="login" v-if="$route.path.includes('session') || $route.path.includes('register')"></div>
        <b-dropdown class="" v-if="$route.path.includes('session') || $route.path.includes('register')" right>
            <template #button-content>
                <img :src="require(`../assets/images/flags/${$i18n.locale}.png`)" alt="" height="24px">
            </template>
            <b-dropdown-item v-for="option in $i18n.availableLocales" :key="option" @click="$root.changeLan(option)">
                <img :src="require(`../assets/images/flags/${option}.png`)" alt="" height="24px">
                <span class="ml-2">{{ $t(option) }}</span>
            </b-dropdown-item>
        </b-dropdown>
        <div v-if="$route.path.includes('session') || $route.path.includes('register')" class="auth-page">
            <img src="../assets/images/logo-text.png" alt="">

        </div>
        <div v-else-if="$route.path.includes('dashboard')" class="content-page d-md-none">
            <div class="d-flex align-items-center">
                <img src="../assets/images/logo-text.png" alt="" height="30px">
                <div class=" flex-grow-1"></div>
                <div class="d-flex align-items-center">
                    <div class="ml-4" @click="$router.push('/web/news')">
                        <img src="../assets/images/actions/news.png" alt="" height="24px" width="24px">
                    </div>

                </div>
            </div>
            <div class="">
                <div class="rank_username">
                    {{ $root.userInfo.username }}
                </div>
                <div class="user-info">
                    {{ $root.userInfo.country.country_code }}{{ $root.userInfo.contact_number }} |
                    {{ $root.userInfo.email }}
                </div>

            </div>
        </div>

        <div class="content-page d-md-block d-none" v-if="!$route.path.includes('sessions') && !$route.path.includes('register')">
            <div class="d-flex align-items-center">
                <img src="../assets/images/logo-text.png" alt="" height="30px">
                <div class=" flex-grow-1"></div>
                <div class="d-flex align-items-center">
                    <b-dropdown class="position-relative top-0 right-0 mr-4" right>
                        <template #button-content>
                            <img :src="require(`../assets/images/flags/${$i18n.locale}.png`)" alt="" height="26px">
                        </template>
                        <b-dropdown-item v-for="option in $i18n.availableLocales" :key="option"
                            @click="$root.changeLan(option)">
                            <img :src="require(`../assets/images/flags/${option}.png`)" alt="" height="26px ">
                            <span class="ml-2">{{ $t(option) }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <div class="mr-4" @click="$router.push('/web/news')">
                        <img src="../assets/images/actions/news.png" alt="" height="24px" width="24px">
                    </div>
                    <div class="" v-b-toggle.sidebar-right>
                        <img src="../assets/images/actions/user.png" alt="" height="30px" width="30px">
                    </div>


                </div>
            </div>

        </div>
        <b-sidebar id="sidebar-right" right shadow>
           <Profile></Profile>
        </b-sidebar>
    </div>

</template>
<script>
import Profile from '../views/menu/profile/main.vue'
export default {
    components: {Profile},
    data() {
        return {
            companies: [
            ],
            isHidden: false
        };
    }
}
</script>

<style>
.login {
    background-image: url('../assets/images/bg/background.png') !important;
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
}
</style>
