<template>

    <b-modal id="modal-news" hide-footer hide-header centered>
        <div class="checkbox1">
            <b-form-checkbox id="checkbox-1" class="text-14" v-model="checkBoxStatus" name="checkbox-1" :value="true"
                :unchecked-value="false" required>
                {{ $t('dont_show_again_today') }}
            </b-form-checkbox>
        </div>
        <carousel :items="1" v-if="$root.latestNews.length > 0" :nav="false" :autoplay="true" :dots="true"
            :stagePadding="0" :margin="10">

            <div class="mb-4" v-for="item in $root.latestNews" :key="item.id">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <div class="text-20 font-weight-700 text-primary2">{{ item.title }}</div>
                    <span class="ml-2 text-20" @click="$bvModal.hide('modal-news')"><i class="fa fa-close"></i></span>

                </div>
                <div class="content">
                    <img :src="item.public_path" alt="" width="100%" class="rounded-1">
                    <div class="text-left mt-3">
                        <div class="text-secondary text-12 mb-2">{{ item.updated_at.split(' ')[0] }}</div>
                        <div class="mb-1 text-14 " v-html="item.description.replace(/(\r\n|\r|\n)/g, '<br>')"></div>

                    </div>

                </div>

            </div>
        </carousel>


    </b-modal>
</template>
<script>
import carousel from "vue-owl-carousel";
export default {
    data() {
        return {
            checkBoxStatus: false,
        }
    },
    watch: {
        checkBoxStatus(val) {
            var date = new Date();
            if (val) {
                localStorage.setItem('dunShow', date.getDate());
            } else {

                localStorage.removeItem('dunShow');
            }
        }
    },
    components: { carousel }
}
</script>