<template>
    <div class="text-left">
        <label v-if="title" :for="name" class="label-text mt-0">
            <div class="d-flex align-items-center">
                {{ title }} <div v-if="!readonly && required" class="text-danger text-left ml-1">*</div>
            </div>
        </label>
        <label v-else :for="name" class="label-text mt-0">
            <div class="d-flex align-items-center">
                {{ $t(name) }} <div v-if="!readonly && required" class="text-danger text-left ml-1">*</div>

            </div>
            <span class="" @click="$router.push('/web/sessions/forget_password')"
                v-if="name == 'password' && !noActions">{{
                    $t('forget_password') }}</span>
            <span class="" @click="$router.push('/web/sessions/forget_secpassword')"
                v-if="name == 'sec_password' && !noActions">{{
                    $t('forget_secpassword') }}</span>
            <span class="" @click="$router.push('/web/profile/forgetGA')" v-if="name == 'gacode'">{{
                $t('forgetGA') }}</span>

        </label>
        <b-input-group class="mb-4">
            <template #prepend v-if="name == 'contact_number'">
                {{ $root.country_code }}
            </template>
            <!-- <b-form-select v-if="isOptions" v-model="inputValue" class="form-control custom-select"
                :options="selectedOptions" value-field="value" text-field="name" :required="!readonly"
                :disabled="readonly">
                <template #first>
                    <option v-for="option in selectedOptions" :key="option.value" :value="option.value"
                        :class="{ 'custom-option': true, 'selected-option': selectedValue === option.value }">
                        {{ option.text }}
                    </option>
                </template>
            </b-form-select> -->

            <b-form-group v-if="radioKeyList.includes(name)" :required="!readonly">
                <b-form-radio v-model="inputValue" v-for="item in checkNameOptions(name)" :key="item.name"
                    :value="item.value">{{
                        item.name
                    }} </b-form-radio>
            </b-form-group>
            <b-dropdown v-else-if="isOptions" :text="selectedOptionText" class="form-control ">
                <b-dropdown-item v-for="option in selectedOptions" :key="option.value"
                    @click="inputValue = option.value" class="custom-dropdown-item">
                    {{ option.name }}
                </b-dropdown-item>
            </b-dropdown>

            <div v-else-if="type == 'textArea'" class="w-100 d-flex flex-column">
                <textarea name="" class="form-control" style="border-radius: 30px;" id="" cols="30" rows="10"
                    v-model="inputValue" :required="!readonly && required" :readonly="readonly"></textarea>
                <span class="mt-2 label-text">
                    {{ $t("no_symbol") }}
                </span>
            </div>

            <div v-else-if="name == 'gacode'" class="input-wrapper">
                <PincodeInput v-model="inputValue" placeholder="" :length="6" />
            </div>

            <input v-else class="form-control" :type="inputType" v-model="inputValue"
                :placeholder="inputType == 'number' && name != 'otp' ? $t('enter_amount') : title ? title : $t(name)"
                :required="!readonly && required" :readonly="readonly" />

            <template #append
                v-if="(name == 'use_point1' || (name == 'invite_link' || name == 'ref_id' || name == 'twoFACode') || defaultInputType == 'password')">
                <span v-if="name == 'use_point1'" class="text-primary font-weight-600" @click="$emit('maxAmount')">{{
                    $t('max') }}</span>
                <span v-if="!noActions && (name == 'invite_link' || name == 'ref_id' || name == 'twoFACode')"
                    class="text-primary font-weight-600" @click="$emit('copyFunction')">
                    <i class="fa fa-copy"></i>
                </span>
                <span v-if="defaultInputType == 'password'" class="ml-1 font-weight-600 text-24"
                    @click="changePasswordType">
                    <i v-if="inputType == 'password'" class="fa fa-eye"></i>
                    <i v-else class="fa fa-eye-slash"></i>
                </span>

            </template>
        </b-input-group>


        <div v-if="name == 'ic_no' && label" class="balance-text">
            {{ $t('*Passport_Number_(For_foreigner)') }}
        </div>

        <div v-if="name == 'withdraw_amount_usdt'" class="balance-text">
            {{ $t('point1') }}: {{ parseFloat($root.userInfo.point1 * 4.2).toLocaleString() }} MYR
            <!-- {{ (parseFloat($root.userInfo['point' + item]) * 4.2).toLocaleString() }} MYR -->
        </div>
        <div v-if="name == 'use_point1'" class="balance-text">
            {{ $t('available_balance') }}: {{ parseFloat($root.userInfo.point1).toLocaleString() }} USDT
        </div>
        <div v-if="name == 'point2'" class="balance-text">
            {{ $t('available_balance') }}: {{ parseFloat($root.userInfo.point2).toLocaleString() }} USDT
        </div>
    </div>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
export default {
    components: {
        PincodeInput
    },
    props: {
        name: String,
        title: String,
        value: null,
        type: String,
        options: Array,
        noActions: {
            type: Boolean,
            default: false
        },
        label: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    // props: ['name', 'title', 'value', 'readonly', 'noActions', 'options'],
    computed: {
        selectedOptionText() {
            const selectedOption = this.selectedOptions.find(option => option.value == this.inputValue);
            return selectedOption ? selectedOption.name : 'Select an option';
        },
        deposit_methodOptions() {
            return [
                { name: 'FPX', value: true },
                { name: this.$t('local_depositor'), value: false },

            ];
        },
        withdrawal_methodOptions() {
            return [
                { name: this.$t('address_book'), value: 0 },
                { name: this.$t('manually'), value: 1 },

            ];
        },
        ID_typeOptions() {
            return [
                { name: this.$t('national_id'), value: '1' },
                { name: this.$t('passport'), value: '2' },

            ];
        },
        walletTypeOptions() {
            return [
                { name: 'USDT(TRC20)', value: 'USDT(TRC20)' },

            ];
        },
        genderOptions() {
            return [
                { name: this.$t('male'), value: '1' },
                { name: this.$t('female'), value: '2' },

            ];
        },
    },
    watch: {
        name() {
            this.checkOptions();
            this.checkType();
        },
        value(val) {
            this.inputValue = val;
        },
        inputValue(val) {
            this.$emit('updateInput', val);

        },
        '$root.countryOptions': 'checkOptions',
    },
    data() {
        return {
            inputValue: '',
            selectedOptions: [],
            isOptions: false,
            needIcon: false,
            // required: false,
            defaultInputType: 'text',
            inputType: 'text',
            radioKeyList: ['deposit_method', 'package_type', 'ID_type', 'withdrawal_method']
        }
    },
    methods: {
        checkNameOptions(name) {
            var foundVariable;
            const keys = Object.keys(this.$options.computed);
            const matchingKey = keys.find(key => key.includes(name));
            if (matchingKey) {
                foundVariable = this[matchingKey];
            }
            if (this.options) {
                return this.options;

            } else
                return foundVariable;

        },
        changePasswordType() {
            if (this.inputType == 'password')
                this.inputType = 'text';
            else
                this.inputType = 'password';
        },
        checkType() {
            var type = 'text';
            switch (this.name) {
                case 'lot':
                    type = 'number';
                    break;
                case 'otp':
                    type = 'number';
                    break;
                case 'use_point1':
                    type = 'number';
                    break;
                case 'price':
                    type = 'number';
                    break;
                case 'point2':
                    type = 'number';
                    break;
                case 'dob':
                    type = 'date';
                    break;
                case 'old_password':
                    type = 'password';
                    break;
                case 'sec_password':
                    type = 'password';
                    break;
                case 'email':
                    type = 'email';
                    break;
                case 'password':
                    type = 'password';
                    break;
                case 'password_confirmation':
                    type = 'password';
                    break;
                case 'new_secpwd':
                    type = 'password';
                    break;
                case 'confirm_new_secpwd':
                    type = 'password';
                    break;

            }
            this.defaultInputType = type;
            this.inputType = type;

        },
        checkOptions() {
            switch (this.name) {
                case 'gender':
                    this.isOptions = true;
                    this.selectedOptions = this.genderOptions;
                    this.inputValue = this.selectedOptions[0].value;
                    break;
                case 'bank_country':
                    this.isOptions = true;
                    this.selectedOptions = this.$root.countryOptions;
                    if (this.selectedOptions.length > 0) {
                        this.inputValue = this.selectedOptions[0].value;
                        this.$root.country_code = this.selectedOptions[0].code;
                    }
                    break;
                case 'country_id':
                    this.isOptions = true;
                    this.selectedOptions = this.$root.countryOptions;
                    if (this.selectedOptions.length > 0) {
                        this.inputValue = this.selectedOptions[0].value;
                        this.$root.country_code = this.selectedOptions[0].code;
                    }
                    break;
                case 'bank_type':
                    this.isOptions = true;
                    this.selectedOptions = this.bankOptions;
                    this.inputValue = this.selectedOptions[0].value;
                    break;
                case 'transfer_type':
                    this.isOptions = true;
                    this.selectedOptions = this.pointOptions;
                    this.inputValue = this.selectedOptions[0].value;
                    break;
                case 'wallet_type':
                    this.isOptions = true;
                    this.selectedOptions = this.walletTypeOptions;
                    this.inputValue = this.selectedOptions[0].value;
                    break;
            }

            if (this.options) {
                this.isOptions = true;
                if (this.options.length > 0) {
                    this.selectedOptions = this.options;
                    this.inputValue = this.selectedOptions[0].value;

                }

            }
        }

    },
    mounted() {
        this.checkOptions();
        this.checkType();
        if (this.value) {
            this.inputValue = this.value;

        }
    }
}
</script>

<style lang="scss">
.vue-pincode-input-wrapper {
    gap: 8px;

    .vue-pincode-input {
        aspect-ratio: 1;
        max-width: 50px !important;
        margin: 0px !important;
        box-shadow: none !important;
        border: 1px solid #ADB5BD !important;
        border-radius: 8px !important;
    }

    .vue-pincode-input:focus {
        border: 3px solid #657BEA !important;
    }

}
</style>