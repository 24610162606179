import { render, staticRenderFns } from "./imageCapture.vue?vue&type=template&id=5860bf84&scoped=true"
import script from "./imageCapture.vue?vue&type=script&lang=js"
export * from "./imageCapture.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5860bf84",
  null
  
)

export default component.exports