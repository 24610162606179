

export default async (to, from, next) => {
  var token = localStorage.getItem("currencyToken") || '';

  // console.log(to);

    if (token.length > 0) {
      // if (userInfo.password2 == null) {
      //   next('/web/set-sec-password');
      // }
      next();
    } else {
      localStorage.removeItem("currencyToken");
      next('/web/sessions/login');
    }
  
};
