<template>
    <div class="main-content">
        <div class="flex-grow-1">
            <Empty v-if="dataList.length == 0"></Empty>
            <div v-else>
                <b-row>
                    <b-col md="6" cols="12" v-for="item in dataList" :key="item.id">
                        <b-card class="package">
                            <img class="package-img"
                                :src="checkImg(item)" alt="" @error="replaceByDefault">
                            <div class="p-3">
                                <div class="text-primary font-weight-700 mb-3">{{ item.package_name }}</div>
                                <b-row class="align-items-end">
                                    <b-col cols="6">
                                        <p class="mb-1 text-12">{{ $t('point2_cost') }}</p>
                                        <h4 class="text-24 font-weight-600 mb-0">
                                            {{ parseFloat(item.country_price).toLocaleString() }}
                                            <span> MYR</span>

                                            <div class="text-12">({{ parseFloat(item.display_price).toLocaleString() }}
                                                RP)</div>
                                        </h4>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-button variant="primary" class="mt-0 text-12"
                                            @click="$bvModal.show('modal-invest-' + item.id)">
                                            {{ $t('invest')
                                            }}</b-button></b-col>
                                </b-row>

                            </div>
                            <b-modal :id="'modal-invest-' + item.id" hide-footer centered
                                :title="$t('invest_package_preview')">
                                <img class="package-img-modal mt-2"
                                    :src="checkImg(item)"
                                    alt="" @error="replaceByDefault">
                                <b-row v-for="jtem in info" :key="jtem" class="mb-2">
                                    <b-col cols="4" class="dialog-msg text-left">{{ $t(jtem) }}</b-col>
                                    <b-col cols="8" class="info-text">{{ item[jtem] }}</b-col>
                                </b-row>
                                <b-form @submit.prevent="submitForm(item)">
                                    <FormInput class="mt-3" :name="'sec_password'" :value="sec_password"
                                        @updateInput="(n) => sec_password = n"></FormInput>
                                    <!-- <div class="position-relative">
                                <FormInput class="mt-3" :name="'otp'" :value="otp" @updateInput="(n) => otp = n">
                                </FormInput>
                                <div class="otp">
                                    <span v-if="timecount < 60">
                                        {{ timecount }}s
                                    </span>
                                    <span class="" v-else @click="getCode">
                                        {{ $t('get_vcode') }}
                                    </span>
                                </div>

                            </div> -->
                                    <div class="d-flex">
                                        <b-button variant="primary" type="submit">{{ $t('invest') }}</b-button>
                                    </div>
                                </b-form>

                            </b-modal>
                        </b-card>
                    </b-col>
                </b-row>

                <b-button class="d-block mx-auto w-auto px-4 mt-3" variant="outline-primary"
                    v-if="lastPage > currentPage" @click="getRecord">{{
                        $t('load_more')
                    }}</b-button>
            </div>

        </div>


    </div>
</template>
<script>
// getUpgradeRecordHistory
import { getPackage, upgradePackage, requestOTP, checkOTP } from '../../system/request/api/api';
import { handleError } from '../../system/request/handleRes';
import Empty from '../../components/empty.vue';
import FormInput from '../../components/formInput.vue';
export default {
    components: { Empty, FormInput },
    data() {
        return {
            currentPage: 0,
            lastPage: 1,
            dataList: [],
            sec_password: '',
            otp: '',
            info: ['package_name', 'display_price'],
            amount_after_tax: 0,
            isExpiring: false,
            tabIndex: 0,
            timecount: 60,
            package_type: '',

        }
    },
    watch: {
        tabIndex(val) {
            if (val == 1) {
                this.package_type = 4;
            } else {
                this.package_type = '';

            }
            this.dataList = [];
            this.currentPage = 0;
            this.getRecord();

        },
        dataList() {
            for (let i = 0; i < this.dataList.length; i++) {
                var val = this.dataList[i]
                if (val.day > 95 && val.package_type != 3) {
                    this.isExpiring = true;
                    break;

                } else if (val.day > 360 && val.package_type == 3) {
                    this.isExpiring = true;
                    break;
                }

            }

        }
    },
    methods: {
        checkImg(item){
            var img;
            try {
                img = require('../../assets/images/record/package/package_' + item.id + '.png');
            } catch (error) {
                img= require('../../assets/images/record/package/package_6.png');
            }

            return img

        },
        replaceByDefault(e) {
            e.target.src = require('../../assets/images/record/package/package_6.png');
        },
        submitForm(item) {
            console.log(item);
            let formData = new FormData();
            let self = this;
            self.$root.isLoading = true;

            formData.append('pay_type', 'point2');
            formData.append('user_group_id', item.id);
            formData.append('sec_password', this.sec_password);
            upgradePackage(formData)
                .then((value) => {
                    if (value.data.error_code == 0) {
                        self.$root.makeToast("success", self.$t('invest_success'), self.$t('invest_success_hint'));
                        self.$router.go(-1)
                    } else {
                        self.$root.makeToast("danger", self.$t(value.data.message), '');
                    }
                    self.$root.getInfo();
                    self.$root.isLoading = false;
                })
                .catch((error) => {
                    self.$root.isLoading = false;
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                });
        },
        getCode() {
            let formData = new FormData();
            // formData.append("email", this.$root.userInfo.email);
            formData.append("otp_type", 'email');
            formData.append("lang", this.$i18n.locale == "zh" ? "cn" : "en");

            this.$root.isLoading = true;
            var result = requestOTP(formData);
            var self = this;

            result
                .then(function (value) {
                    console.log(value.data);
                    if (value.data.error_code == 0) {
                        // 
                        self.$root.makeToast("success", self.$t("otp_sent"), '');
                        // self.step = 2;
                        self.startCount = true;
                        self.myVar = setInterval(() => {
                            self.timecount -= 1;
                            if (self.timecount == 0) {
                                self.timecount = 60;
                                clearInterval(self.myVar);
                            }
                        }, 1000);
                    } else {
                        self.$root.makeToast("danger", value.data.message);
                    }
                    self.$root.isLoading = false;
                })
                .catch(function (error) {
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                    self.$root.isLoading = false;
                });

        },
        sendOTP(item) {
            var self = this;
            let formData = new FormData();
            formData.append("otp", this.otp);
            formData.append("type", 'early termination');
            var result = checkOTP(formData);
            self.$root.isLoading = true;

            result
                .then(function (value) {
                    console.log(value.data);
                    if (value.data.error_code == 0) {
                        self.surrender(item);
                    } else {
                        self.$root.makeToast("danger", self.$t(value.data.message), '');
                        self.$root.isLoading = false;
                    }
                })
                .catch(function (error) {
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                    self.$root.isLoading = false;
                });
        },

        getPackage() {
            var self = this;
            self.currentPage += 1;
            self.$root.isLoading = true;
            var result = getPackage();
            // self.dataList = [];

            result
                .then(function (value) {
                    var dataList = value.data.data.user_group;
                    for (let i = 0; i < dataList.length; i++) {
                        // if (dataList[i].status == 1 || dataList[i].status == 0) {
                        self.dataList.push(dataList[i]);
                        // }
                    }
                    self.$root.isLoading = false;
                })
                .catch(function (error) {
                    self.$root.isLoading = false;
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                });
        },
    },
    mounted() {
        // this.$root.isLoading = true;
        // this.$bvModal.show('modal-earlyterminate')
        this.getPackage();
    }
}
</script>
