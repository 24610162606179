<template>
    <div>
        <Header class="d-md-none" :title="$t('')" @action="$router.go(-1)" :record="'depositRecord'"></Header>
        <div class="main-content">
            <b-tabs>
                <!-- <b-tab>
                    <template #title>
                        <div style="text-transform: uppercase;">{{ $t('crypto') }}</div>
                        <div class="dot"></div>

                    </template>
<b-form @submit.prevent="submitForm(true)">
    <div v-for="value, name in crypto" :key="name" class="">
        <div v-if="name == 'amount'">
            <FormInput :name="name" :title="`${$t('amount')} (USDT)`" :value="value"
                @updateInput="(n) => crypto[name] = n" :readonly="name == 'currency'">
            </FormInput>


        </div>
        <FormInput v-else-if="name == 'bank_country'" :name="name" :title="$t('crypto_type')" :value="value"
            @updateInput="(n) => crypto[name] = n" :options="cryptoOptions" :readonly="name == 'currency'">
        </FormInput>
        <FormInput v-else-if="name != 'deposit_type' && name != 'currency' && name != 'deposit_method'" :name="name"
            :value="value" @updateInput="(n) => crypto[name] = n" :readonly="name == 'currency'">
        </FormInput>
    </div>
    <div class="withdraw-hint">
        <b-button variant="primary" class="mt-2" type="submit">{{ $t('next')
            }}</b-button>

    </div>

</b-form>


</b-tab> -->
                <b-tab>
                    <template #title>
                        <div style="text-transform: uppercase;">{{ $t('fiat') }}</div>
                        <div class="dot"></div>

                    </template>
                    <b-form @submit.prevent="submitForm(false)">
                        <div v-if="system_bank.length > 0">
                            <FormInput :name="'bank_name'" :value="selectedBank" @updateInput="(n) => selectedBank = n"
                                :options="system_bank" :readonly="true">
                            </FormInput>
                            <div v-for="value, name in bankInfo" :key="name" class="">
                                <FormInput :name="name" :value="value" @updateInput="(n) => fiat[name] = n"
                                    :readonly="true">
                                </FormInput>

                            </div>

                        </div>
                        <div v-for="value, name in fiat" :key="name" class="">
                            <div v-if="name == 'amount'">
                                <FormInput :name="name" :title="`${$t('amount')} (MYR)`" :value="value"
                                @updateInput="(n) => fiat[name] = n" :readonly="name == 'currency'">
                                </FormInput>
                                <div class="position-relative">
                                    <FormInput :name="name" :title="`${$t('RP')}`"
                                        :value="value.length > 0 ? (parseFloat(value) / bankRate).toFixed(2) : ''"
                                        :readonly="true">
                                    </FormInput>
                                    <div class="label-text-right">1 MYR = {{  'RP ' + bankRate }}</div>

                                </div>


                            </div>
                            <div v-if="name == 'sec_password'">
                                <FormInput :name="name" :title="`${$t('sec_password')}`" :value="value"
                                @updateInput="(n) => fiat[name] = n" :readonly="name == 'currency'">
                                </FormInput>
                            </div>
                            <!-- <FormInput v-else-if="name == 'bank_name'" :name="name" :options="system_bank"
                                :value="value" @updateInput="(n) => fiat[name] = n">
                            </FormInput>
                            <FormInput v-else-if="name != 'currency' && name != 'deposit_type'" :name="name"
                                :value="value" @updateInput="(n) => fiat[name] = n" :readonly="name == 'currency'">
                            </FormInput> -->
                        </div>
                        <label class="label-text mt-0">{{ $t('upload') }} {{ $t('image') }}</label>
                        <ImageCapture :value="file" :size="'lg'" @captureImage="(n) => file = n"
                            :title="$t('ic_front')">
                        </ImageCapture>
                        <div class="withdraw-hint">
                            <b-button variant="primary" class="mt-2" type="submit">{{ $t('next')
                                }}</b-button>

                        </div>

                    </b-form>


                </b-tab>
            </b-tabs>

        </div>

    </div>
</template>
<script>
import { doDeposit, getDepositBank } from '../../system/request/api/api';
import FormInput from '../../components/formInput.vue';
import Header from '../../components/header_inner.vue';
import { handleError } from '../../system/request/handleRes';
import ImageCapture from '@/components/imageCapture.vue';
export default {
    components: { Header, FormInput, ImageCapture },
    computed: {

        cryptoOptions() {
            return [
                { name: 'USDT', value: '999' },

            ];
        },
    },
    data() {
        return {
            bankInfo: {
                bank_number: '',
                bank_user: '',
            },
            fiat: {
                currency: 'MYR',
                deposit_type: 'point2',
                // fpx: '',
                amount: '',
                sec_password: '',

            },
            depositImage: null,
            crypto: {
                bank_country: 999,
                currency: 'usdt',
                deposit_type: 'usdt',
                deposit_method: '',
                amount: '',
                sec_password: '',

            },
            selectedBank: '',
            system_bank: [],
            bankRate: 0,
            file:'',
        }
    },
    watch: {
        selectedBank(val) {
            var c = this.system_bank.find((el) => el.value == val);
            var d = this.$root.countryOptions.find((el) => el.value == val);
            console.log(c)
            if (c && d) {
                this.fiat.currency = d['symbol'];
                this.bankRate = d['rate'];

                this.bankInfo.bank_number = c['bank_number'];
                this.bankInfo.bank_user = c['bank_user'];

            }

        }
    },
    methods: {
        getDepositBank() {
            var result = getDepositBank();
            var self = this;
            this.isLoading = true;
            result
                .then(function (value) {
                    self.isLoading = false;
                    self.system_bank = value.data.data.system_bank;
                    self.system_bank.forEach((el) => {
                        el['value'] = el.bank_country;
                        el['name'] = el.bank_name;
 
                    });
                })
                .catch(function () {
                    self.isLoading = false;
                });
        },
        submitForm(isCrypto) {
            console.log(this.fiat);
            let formData = new FormData();

            var formList = isCrypto ? this.crypto : this.fiat;

            for (const key in formList) {
                let element = formList[key];
                if (key == 'amount') {
                    if (formList['bank_country'] == 4 && element < 100) {

                        this.$root.makeToast("danger", this.$t('deposit_fail'), this.$t('amount_cannot_less_than') + " 100 USDT");
                        return;

                    } else if (element < 50) {

                        this.$root.makeToast("danger", this.$t('deposit_fail'), this.$t('amount_cannot_less_than') + " 50 MYR");
                        return;

                    }
                    element = parseFloat((element / 4.2).toFixed(2));

                    formData.append('amount', element);
                }
                if (key == 'deposit_method') {
                    formData.append("fpx", (formList['bank_country'] == 2 && element) ? true : false);

                } else if (key == 'bank_country')
                    formData.append('country_id', element);

                else
                    formData.append(key, element);
            }
        
            formData.append('file', this.file);
            formData.append('system_bank_id', 1);
            formData.append('country_id', 2);
            var result = doDeposit(formData);
            var self = this;
            self.$root.isLoading = true;

            result
                .then(function (value) {
                    if (value.data.data.error_code == 0) {
                        // self.$root.makeToast("success", self.$t("deposit_success"), '');
                        if (formList['bank_country'] == 2 && formList['deposit_method']) {
                            console.log(value.data);
                            setTimeout(() => {
                                location.href = value.data.data.data;
                            }, 0);
                        } else {
                            setTimeout(() => {
                                location.href = value.data.data.data.redirect_url;
                            }, 0);
                        }
                        self.$router.go(-1);
                        self.$bvModal.hide(`modal-deposit`)

                    } else {
                        self.$root.makeToast("success", self.$t(value.data.message), '');
                    }
                    self.$root.isLoading = false;
                    self.sec_pwd = "";
                })
                .catch(function (error) {
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                    self.$root.isLoading = false;
                    self.sec_pwd = "";
                });
        },

    },
    mounted() {
        this.getDepositBank();
    }

}</script>