<template>
    <div class="main-content ">
        <b-tabs class="" v-model="tabIndex">
            <b-tab v-for="item in 2" :key="'tab-' + item">
                <template #title>
                    <div>{{ $t('point' + item) }}</div>
                    <div class="dot"></div>

                </template>
                <div class="point mt-4" :class="'point' + item">
                    <div class="image-bg"></div>
                    <p class="text-16 mb-2 text-center">{{ $t('point' + item) }}</p>
                    <div class="d-flex align-items-center justify-content-center">
                        <img class="" :src="require(`../../assets/images/record/point${item}.png`)" alt="">
                        <h4 class="text-24 font-weight-600 ml-2 mb-0">    
                            <span v-if="item == '1'" class="text-16"> 
                                {{ (parseFloat($root.userInfo['point' + item]) * 4.2).toFixed(2).toLocaleString() }} MYR
                            </span> 
                            <span v-else-if="item == '2'" class="text-16">
                                {{ parseFloat($root.userInfo['point' + item]).toLocaleString() }} RP</span>
                        </h4>
                    </div>

                </div>


                <b-button variant="primary" v-if="item == 1"
                  @click="movePage('withdraw')">
                 {{ $t('withdraw')
                    }}</b-button>
                <b-row v-if="item == 2" class="gutters-sm">
                    <b-col cols="6">
                        <b-button variant="primary" block @click="movePage('deposit')">{{ $t('deposit')
                            }}</b-button></b-col>
                    <b-col cols="6">
                        <b-button variant="outline-primary" @click="movePage('invest')">{{ $t('invest')
                            }}</b-button></b-col>
                </b-row>
                <b-button variant="primary" v-if="item == 3 || item == 4" @click="$router.push('/web/convert')">{{
                    $t('convert')
                }}</b-button>

                <h4 class="section_title mt-4">{{ $t('transaction_history') }}</h4>

                <Empty v-if="dataList.length == 0"></Empty>
                <div v-else>
                    <b-card class="record mb-2" @click="passValue(item)" v-for="item in dataList" :key="item.id">
                        <div class="d-flex" v-if="tabIndex == 4">
                            <img v-if="point5Status == 0" :src="require('../../assets/images/record/purchase.png')"
                                alt="">
                            <img v-else :src="require('../../assets/images/record/vesting.png')" alt="">
                            <div class="w-50">
                                <p class="record-title">
                                    {{ point5Status == 0 ? $t('purchase') : $t('vesting') }}
                                </p>
                                <p class="date mb-0">{{ item.created_at }}</p>
                            </div>
                            <div class="flex-grow-1"></div>
                            <div class="text-right">
                                <p class="info-text">{{ '+' }} {{ parseFloat(item.amount).toLocaleString() }}
                                    <span>GAC</span>
                                </p>
                                <p class="status"></p>
                            </div>

                        </div>
                        <div class="d-flex" v-else>
                            <img :src="require('../../assets/images/record/' + checkType(item) + '.png')" alt="">
                            <div class="w-50">
                                <p class="record-title"
                                    v-html="convertString(item['detail' + ($i18n.locale == 'zh' ? '' : $i18n.locale)])">
                                </p>
                                <p class="date mb-0">{{ item.created_at }}</p>
                            </div>
                            <div class="flex-grow-1"></div>
                            <div class="text-right">
                                <p class="info-text">{{ item.action }} 
                                    {{ Number((parseFloat(item.found) * 4.2).toFixed(2)).toLocaleString() }}
                                    <span>MYR</span>
                                </p>
                                <p class="status"></p>
                            </div>

                        </div>

                    </b-card>
                    <b-button class="d-block mx-auto w-auto px-4 mt-3" variant="outline-primary"
                        v-if="lastPage > searchParams.page" @click="getRecord">{{
                            $t('load_more')
                        }}</b-button>

                </div>

            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import { getUserWalletRecord } from '@/system/request/api/api';
import Empty from '../../components/empty.vue';
export default {
    data() {
        return {
            searchParams: {
                wallet: '',
                page: 0,
            },
            tabIndex: null,
            dataList: [],
            lastPage: 1,
            point5Status: 0,
        }
    },
    components: { Empty },
    watch: {
        point5Status() {
            this.searchParams = {
                wallet: 'point5',
                page: 0,
            };
            this.dataList = [];

            this.getRecord();

        },
        tabIndex(val) {
            this.searchParams = {
                wallet: 'point' + (val + 1),
                page: 0,
            };
            this.dataList = [];

            this.getRecord();
        }
    },
    methods: {
        movePage(route) {
            if (window.outerWidth > 480) {
                this.$bvModal.show(`modal-${route}`)
            } else {
                this.$router.push(`/web/${route}`);

            }
        },
        convertString(value) {
            var text = value.replace('<br>', '');
            text = text.replace(':', ': ');
            return text;

        },
        checkType(item) {
            var img = '';
            switch (item.found_type) {
                case 98:
                    img = 'withdraw';
                    break;
                case 210:
                    img = 'withdraw';
                    break;
                case 8:
                    img = 'convert';
                    break;
                case 11:
                    img = 'invest';
                    break;
                case 1000:
                    img = 'invest';
                    break;
                case 1002:
                    img = 'invest';
                    break;
                case 1004:
                    img = 'invest';
                    break;

                default:
                    console.log(item.found_type)
                    img = 'invest';
                    break;
            }
            return img;

        },
        passValue(data) {
            data['point'] = this.tabIndex + 1;
            this.$router.push({
                name: "walletDetails",
                params: { data }
            });
        },
        getRecord() {
            var token = localStorage.getItem("currencyToken") || '';
            // this.tabIndex = 0;
            if (token.length == 0) {
                return;
            }
            this.searchParams.page += 1;
            var params = '';
            for (const key in this.searchParams) {
                const element = this.searchParams[key];
                params = params + key + '=' + element + '&';
            }
            if (this.searchParams.wallet == 'point5') {
                params = params + 'status=' + this.point5Status + 1;
            }
            console.log(params)

            var self = this;
            self.$root.isLoading = true;
            var result
            result = getUserWalletRecord(params);
            result
                .then(function (value) {
                    value.data.data.record.data.forEach(element => {
                        self.dataList.push(element);
                    });
                    self.lastPage = value.data.data.record.last_page;
                    self.$root.isLoading = false;


                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        var int = parseInt(this.$route.query.type) || 0;
        if (int)
            this.tabIndex = int;
    }


}
</script>
<style scoped lang="scss">
.point {
    background: linear-gradient(180deg, #883AC7 0%, #7340D9 100%);
    color: white;

    p {

        color: white;
    }
}
</style>