<template>
    <div class="no_data">
        <img src="../assets/images/actions/empty.png" alt="">
        <div class="mt-3">
            <span>{{ $t('no_data') }}</span>
        </div>

    </div>
</template>
<script>
export default {

}</script>