<template>
    <div class="topbar bg-md-transparent">
        <div class="inner-page">
            <b-row class="align-items-center d-md-none">
                <b-col cols="1" class="text-left text-white">
                    <span @click="$emit('action')">
                        <i class="fa fa-chevron-left"></i>
                    </span>
                </b-col>
                <b-col class="text-left text-white text-20 font-weight-600 flex-grow-1">
                    <div v-if="title">
                        {{ $t(title) }}

                    </div>
                </b-col>
                <b-col v-if="record" cols="2" class="text-right text-white">
                    <div class="">
                        <span @click="$router.push(record)">
                            <i class="fa fa-history"></i>
                        </span>

                    </div>
                </b-col>
            </b-row>
            <div class="d-none d-md-block text-left">
                <p class="text-18 font-weight-700 " @click="$router.go(-1)"><i class="fa-solid fa-arrow-left-long"></i> {{ $t('back') }}</p>
                <h3 class="text-primary text-24 font-weight-700">{{ $t(title) }}</h3>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    props: ['title', 'record'],
    data() {
        return {
        };
    }
}
</script>
<style>
.topbar {
    /* background-image: url('../assets/images/bg/bg1.png') !important; */
}
</style>
