<template>
    <div class="mb-4">
        <input type="file" name="image" accept="image/*" style="display: none" @change="setImage" ref="myBtn" />
        <div class="upload-box" :class="size" @click="myClickEvent">
            <div v-if="!imgSrc" class="upload-hint text-28 text-black">
                <div class="upload-text">
                    <i class="fa fa-plus">
                    </i>
                    <div>
                        {{ $t('upload') }} {{ title }}
                    </div>
                </div>
            </div>
            <img v-if="imgSrc" :src="imgSrc" style="
                      width: auto;
                      max-width: 100%;
                      height: 100%;
                      position: absolute;
                      object-fit: cover;
                      z-index: 2;
                    " />
        </div>
    </div>
</template>

<script>
export default {
    props: ['th', 'size', 'title', 'value', 'readonly'],
    data() {
        return {
            imgSrc: "",
        }
    },
    methods: {
        myClickEvent() {
            if(this.readonly){
                window.location.href = this.value;
                return;
            }
            const elem = this.$refs.myBtn;
            elem.click();
        },
        setImage(e) {
            const file1 = e.target.files[0];

            const validExtensions = ['jpg', 'jpeg', 'png'];
            const fileExtension = file1.name.split('.').pop().toLowerCase();

            if (!validExtensions.includes(fileExtension)) {
                alert("Please select a jpg or png image file");
                return;
            }

            if (file1.name.includes(".php")) {
                alert("Please select an image file");
                return;
            }

            if (!file1.type.includes("image/")) {
                alert("Please select an image file");
                return;
            }

            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = (event) => {
                    this.imgSrc = event.target.result;
                };
                reader.readAsDataURL(file1);
            } else {
                alert("Sorry, FileReader API not supported");
            }
            this.$emit('captureImage', file1);
        }

    },
    mounted() {
        if (this.value)
            this.imgSrc = this.value;
    }
}

</script>

<style scoped></style>