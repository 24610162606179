<template>
  <div :id="!$route.path.includes('landing') ? 'app' : ''">
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">

        <div class="loading-effect" style="top: 50%;left: 50%;">
          <div class="icon-button" :style="{ 'background-image': 'url(' + logo + ')' }"
            style="height: 3rem;width: auto;">
          </div>
        </div>
        <div class="loading-effect">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner">

          </b-spinner>

        </div>
        <div class="loading-effect" style="top: 80%;">
          {{ $t('loading...') }}

        </div>
      </div>
    </div>
    <Header></Header>
    <b-row no-gutters class="justify-content-center">
      <b-col cols="0" md="2" class="d-none d-md-block"
        v-if="!$route.path.includes('sessions') && !$route.path.includes('register')">
        <b-card class="side-menu">
          <div class="sidebar" v-for="item in footer" :key="item.name" @click="$router.push(item.path)">
            <img v-if="$route.path.includes(item.path)" :src="require('./assets/images/footer/' + item.name + '_a.png')"
              alt="" height="24px" width="24px">
            <img v-else :src="require('./assets/images/footer/' + item.name + '.png')" alt="" height="24px"
              width="24px">
            <p class="ml-3" :class="{ 'active': $route.path.includes(item.path) }">{{ $t(item.name) }}</p>
          </div>

        </b-card>
      </b-col>
      <b-col cols="12" md="7">
        <router-view></router-view>
      </b-col>
      <b-col cols="0" md="3" class="d-none d-md-block"
        v-if="!$route.path.includes('sessions') && !$route.path.includes('register')">
        <Wallet></Wallet>
      </b-col>
    </b-row>

    <b-modal id="modal-deposit" :title="$t('deposit')" hide-footer><template #modal-title>
        <div class="d-flex justify-content-between w-100">
          <div>
            {{ $t('deposit') }}

          </div>
          <div>
            <span @click="$bvModal.hide('modal-deposit');$router.push('/web/depositRecord')">
              <i class="fa fa-history"></i>
            </span>

          </div>



        </div>
      </template>
      <Deposit></Deposit>
    </b-modal>
    <b-modal id="modal-withdraw" :title="$t('withdraw')" hide-footer>
      <template #modal-title>
        <div class="d-flex justify-content-between w-100">
          <div>
            {{ $t('withdraw') }}

          </div>
          <div>
            <span @click="$bvModal.hide('modal-withdraw');$router.push('/web/withdrawRecord')">
              <i class="fa fa-history"></i>
            </span>

          </div>



        </div>
      </template>
      <Withdraw></Withdraw>
    </b-modal>
    <b-modal id="modal-invest" size="lg" :title="$t('invest')" hide-footer>
      <Invest></Invest>
    </b-modal>

    <ModalError></ModalError>
    <ModalNews></ModalNews>

  </div>
</template>

<script>
import Header from './components/header.vue';
import ModalError from './errorMsg.vue';
import ModalNews from './newsModal.vue';
import Wallet from './views/menu/wallet.vue';
import Deposit from './views/actions/deposit.vue';
import Withdraw from './views/actions/withdraw.vue';
import Invest from './views/menu/invest.vue';
export default {
  components: {
    Header, ModalError, ModalNews, Deposit, Withdraw, Invest, Wallet
  },
  watch: {
    $route() {
      var title = this.$route.path.split('/');
      this.title = title[title.length - 1];
      this.path = this.title;

    },
  },
  name: 'MPM',
  data() {
    return {
      screenWidth: 0,
      title: '',
      path: '',
      username: '',
      address: '',
      logo: require('./assets/images/logo-text.png'),

      footer: [
        { name: 'home', path: '/web/dashboard' },
        { name: 'invest', path: '/web/invest' },
        { name: 'commission', path: '/web/commission' },
        { name: 'transaction_history', path: '/web/transactionHistory' },
      ]
    }
  },
  methods: {
    copy(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          alert(`${this.$t('copySuccess')}: ` + value);
        })
        .catch((error) => {
          alert(error);
        });
    },
    logout() {
      localStorage.removeItem('currencyToken');
      localStorage.removeItem('info');
      this.$router.push('/web/sessions/login');
    },
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  created() {
    this.screenWidth = screen.width;

  },
  mounted() {
    var title = this.$route.path.split('/');
    this.title = title[title.length - 1];

    this.path = this.title;
    var info = JSON.parse(localStorage.getItem('info'));
    if (info)
      this.$root.username = info.username;

    this.$root.getCountryList();
    // this.$root.getCountryDepositList();
    var token = localStorage.getItem('currencyToken');
    if (token) {
      this.$root.getInfo();
      this.$root.getTicket(1);

    }
  }
}
</script>

<style>
#app {
  font-family: 'Inter' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  position: relative;

  /* background: url('./assets/images/bg/background.png') !important; */
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
