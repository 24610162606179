<template>
    <div class="flex-grow-1 position-relative d-flex flex-column">
        <Header class="d-md-none" :title="step == 1 ? $t('withdraw') : $t('withdraw_preview')"
            @action="step > 1 ? step = 1 : $router.go(-1)" record="withdrawRecord"></Header>
        <div class="main-content flex-grow-1 d-flex flex-column" v-if="step == 1">
            <b-tabs class="flex-grow-1 d-flex flex-column" v-model="tabIndex">
                <b-tab v-for="item in methods" :key="item.name">
                    <template #title>
                        <div>{{ $t(item.name) }}</div>
                        <div class="dot"></div>
                    </template>
                    <!-- {{ methods[tabIndex].params }} -->
                    <!-- <b-form @submit.prevent="checkGA"> -->
                    <b-form @submit.prevent="submitForm">
                        <div v-if="tabIndex == 1">
                            <FormInput :name="'withdrawal_method'" :value="withdrawal_method"
                                @updateInput="(n) => withdrawal_method = n">
                            </FormInput>
                            <div v-for="value, name in methods[tabIndex].params" :key="name">
                                <FormInput :name="name" :value="value"
                                    @updateInput="(n) => methods[tabIndex].params[name] = n"
                                    :readonly="withdrawal_method == '0'">
                                </FormInput>

                            </div>

                        </div>
                        <div v-for="value, name in form" :key="name" class="position-relative">
                            <FormInput :name="name" :value="value" @updateInput="(n) => form[name] = n">
                            </FormInput>
                            <!-- <div v-if="name == 'otp'" class="otp">
                                <span v-if="timecount < 60">
                                    {{ timecount }}s
                                </span>
                                <span class="" v-else @click="getCode">
                                    {{ $t('get_vcode') }}
                                </span>

                            </div> -->
                        </div>
                        <!-- <div class="withdraw-hint" v-if="useOldWithdraw">
                            <div v-if="tabIndex == 0">
                                <div v-if="methods[tabIndex].params['bank_country'] == 4"
                                    v-html="$t(item.name + '_hint_sg')"></div>
                                <div v-else v-html="$t(item.name + '_hint')"></div>
                            </div>
                            <div v-else>
                                <div v-html="$t(item.name + '_hint')"></div>
                            </div>
                            <b-button variant="primary" class="mt-2" type="submit">
                                {{ $t('next') }}
                            </b-button>
                        </div> -->
                        <div class="d-block d-md-none" style="margin-bottom: 250px;"></div>
                        <div class="withdraw-hint">
                            <div v-if="tabIndex == 0">
                                <div v-if="methods[tabIndex].params['bank_country'] == 4"
                                    v-html="$t(item.name + '_hint_sg_new')"></div>
                                <div v-else v-html="$t(item.name + '_hint_new')"></div>
                            </div>
                            <div v-else>
                                <div v-html="$t(item.name + '_hint_new')"></div>
                            </div>
                            <b-button variant="primary" class="mt-2" type="submit">
                                {{ $t('next') }}
                            </b-button>
                        </div>
                    </b-form>
                </b-tab>
            </b-tabs>

        </div>


    </div>
</template>

<script>
import FormInput from '../../components/formInput.vue'
import Header from '../../components/header_inner.vue'
import { withdraw, withdrawCash, withdrawCashNew, withdrawMasterCard, checkOTP, requestOTP, getWithdrawRecord } from '@/system/request/api/api';
import { handleError } from '../../system/request/handleRes';
export default {
    components: { Header, FormInput },

    data() {
        return {
            step: 1,
            tabIndex: 0,
            methods: [
                {
                    name: 'bank',
                    fee: 12,
                    old_fee: 10,
                    min: 50,
                    params: {
                        bank_country: '',
                        bank_name: '',
                        bank_user: '',
                        bank_number: '',
                        branch: '',
                    }
                },
                // {
                //     name: 'crypto',
                //     fee: 10,
                //     old_fee: 6,
                //     min: 10,
                //     params: {
                //         wallet_type: '',
                //         address: '',
                //     },
                // },

            ],
            withdrawal_method: '0',
            amount_content: {
                // withdraw_amount: '',
                // fee: '',
                fee_usdt: '',
                amount_received_usdt: '',
                amount_received: '',

            },
            form: {
                withdraw_amount_usdt: '',
                // otp: '',
                sec_password: '',

            },
            gacode: '',
            wallet: 'point1',
            bankRate: 0,
            timecount: 60,
            startCount: false,
            myVar: null,
            useOldWithdraw: false
        }
    },
    watch: {
        withdrawal_method(val) {
            this.methods[this.tabIndex].params.address = this.$root.cryptoInfo['usdt_address'];
            if (val == 1) {
                this.methods[this.tabIndex].params.address = '';

            }
        },
        tabIndex(val) {
            setTimeout(() => {
                this.$root.updatePb();
            }, 500);

            this.checkUI(val)
        },
        step() {
            setTimeout(() => {
                this.$root.updatePb();
            }, 500);
        },
        '$root.userInfo'(val) {
            if (val.cash_withdraw_fullname != null) {
                this.methods[3].params.fullname = val.cash_withdraw_fullname;
                this.methods[3].params.ic_number = val.cash_withdraw_ic;
                this.methods[3].params.contact_number = val.cash_withdraw_contact;
                // this.$root.blockWithdraw = false;

            }
            // else {
            //     this.$root.blockWithdraw = true;
            //     this.$root.makeToast('info', this.$t('set_withdraw'), this.$t('set_withdraw_hint'))
            //     this.$router.push('/web/profile/witdhraw_address_book?type=3');

            // }
        },
        '$root.cryptoInfo'(val) {
            if (val != null) {
                this.methods[1].params['address'] = val['usdt_address'];

            }
        },
        '$root.bankInfo'(val) {
            for (const formKey in this.methods[0].params) {
                for (const key in val) {
                    if (formKey == key) {
                        this.methods[0].params[key] = val[key];

                    }
                }
            }
        }
    },
    methods: {
        checkUI(val) {
            switch (val) {
                case 0:
                    this.form = {
                        withdraw_amount_usdt: '',
                        // otp: '',
                        sec_password: '',

                    };
                    break;
                case 1:
                    this.form = {
                        withdraw_amount_usdt: '',
                        // otp: '',
                        sec_password: '',

                    };
                    break;
                case 2:
                    this.form = {
                        withdraw_amount_usdt: '',
                        // otp: '',
                        sec_password: '',

                    };
                    break;
                case 3:
                    this.form = {
                        withdraw_amount_usdt: '',
                        // otp: '',
                        sec_password: '',

                    };
                    break;
            }
        },
        checkGA() {
            if (this.tabIndex == 3 || this.tabIndex == 0) {

                if (this.form['otp'] !== undefined) {
                    this.checkOTP();

                } else {
                    this.movepage();
                }
                return;
            } else {
                if (!(this.gacode.length > 0)) {
                    this.$root.makeToast("danger", this.$t('please_input_gacode'), '');
                    return;
                }
                if (this.form['otp'] !== undefined) {
                    this.checkOTP();
                } else {
                    this.movepage();
                }
                return;
            }
        },
        getCode() {
            let formData = new FormData();
            formData.append("otp_type", 'email');
            // formData.append("lang", this.$i18n.locale == "zh" ? "cn" : "en");

            this.$root.isLoading = true;
            var result = requestOTP(formData);
            var self = this;

            result
                .then(function (value) {
                    console.log(value.data);
                    if (value.data.error_code == 0) {
                        // 
                        self.$root.makeToast("success", self.$t("otp_sent"), '');
                        // self.step = 2;
                        self.startCount = true;
                        self.myVar = setInterval(() => {
                            self.timecount -= 1;
                            if (self.timecount == 0) {
                                self.timecount = 60;
                                clearInterval(self.myVar);
                            }
                        }, 1000);
                    } else {
                        self.$root.makeToast("danger", value.data.message);
                    }
                    self.$root.isLoading = false;
                })
                .catch(function (error) {
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                    self.$root.isLoading = false;
                });

        },


        checkOTP() {
            if (this.$root.userInfo.check_kyc.can_withdraw == 'failed' || this.$root.userInfo.check_kyc.can_withdraw == 'pending') {
                this.$root.makeToast("danger", this.$t('kyc_block_withdraw'), '');
                return;


            }
            var self = this;
            let formData = new FormData();
            formData.append("otp", this.form.otp);
            var result = checkOTP(formData);
            self.$root.isLoading = true;

            result
                .then(function (value) {
                    console.log(value.data);
                    if (value.data.error_code == 0) {
                        // self.$root.makeToast("success", self.$t(value.data.message));
                        self.movepage();
                    } else {
                        self.$root.makeToast("danger", self.$t(value.data.message), '');
                    }
                    self.$root.isLoading = false;
                })
                .catch(function (error) {
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                    self.$root.isLoading = false;
                });
        },
        checkCountry(id) {
            var country = this.$root.countryOptions.find((el) => el.value == id)['name'];
            return country;

        },
        submitForm() {
            let formData = new FormData();
            let self = this;
            for (const key in this.methods[this.tabIndex].params) {
                var formList = this.methods[this.tabIndex].params;
                const element = formList[key];
                formData.append(key, element);
                if (element == null || element.length == 0 || element == '') {
                    this.$root.makeToast('info', this.$t('set_withdraw'), this.$t('set_withdraw_hint'));
                    this.$router.push('/web/profile/bank-information');
                    this.$bvModal.hide('modal-withdraw');
                    return;

                }
            }
            for (const key in this.form) {
                let element = this.form[key];
                if (key == 'withdraw_amount_usdt') {


                    if (element < 50) {
                        this.$root.makeToast("danger", this.$t('withdraw_failed'), this.$t('min_wihtdrawa_amount') + ' : 50 MYR');

                        return;

                    }
                    element = parseFloat((element / 4.2).toFixed(2));

                    formData.append('amount', element);
                }
                else {
                    formData.append(key, element);

                }
            }
            formData.append("wallet", this.wallet);
            formData.append("google2fa_code", this.gacode);

            self.$root.isLoading = true;
            var result;
            switch (this.tabIndex) {
                case 0:
                    result = withdrawCash(formData);
                    break;
                case 1:
                    formData.append("fee", this.amount_content.fee_usdt);
                    formData.append("withdraw_type", this.withdrawal_method == '0' ? 'withdraw' : 'manually_withdraw');
                    result = withdraw(formData);
                    break;
                case 2:
                    result = withdrawMasterCard(formData);
                    break;
                case 3:
                    result = withdrawCashNew(formData);
                    break;
            }
            result
                .then((value) => {
                    if (value.data.error_code == 0) {
                        self.$root.makeToast("success", self.$t(value.data.message), '');
                        self.$router.go(-1)
                        self.$bvModal.hide('modal-withdraw');
                        self.$root.getInfo();
                    } else {
                        self.$root.makeToast("danger", self.$t(value.data.message), '');
                    }
                    self.$root.getInfo();
                })
                .catch((error) => {
                    self.$root.isLoading = false;
                    self.$root.makeToast("warning", self.$t(handleError(error)), '');
                });
        },
        movepage() {
            var needSetWithdraw = false;
            for (const key in this.methods[this.tabIndex].params) {
                var element = this.methods[this.tabIndex].params[key];
                if (element == null || element.length == 0 || element == '') {
                    needSetWithdraw = true;

                }
            }
            if (needSetWithdraw) {
                // this.$root.$bvModal.action = true;
                this.$root.makeToast('info', this.$t('set_withdraw'), this.$t('set_withdraw_hint'));
                this.$router.push('/web/profile/witdhraw_address_book?type=' + this.tabIndex);
                return;
            }
            var tmpAmount = this.form['withdraw_amount_usdt'];
            if (this.tabIndex == 0) {
                // alert(tmpAmount)
                if (this.methods[this.tabIndex].params['bank_country'] == 4 && tmpAmount < 100) {
                    this.$root.makeToast("danger", this.$t('withdraw_failed'), this.$t('min_wihtdrawa_amount') + ' : 100 USDT');

                    return;

                } else if (tmpAmount < 10) {
                    this.$root.makeToast("danger", this.$t('withdraw_failed'), this.$t('min_wihtdrawa_amount') + ' : ' + this.methods[this.tabIndex].min + ' USDT');
                    return;

                }

            } else {
                console.log(this.methods[this.tabIndex].min)
                if (tmpAmount < this.methods[this.tabIndex].min) {
                    this.$root.makeToast("danger", this.$t('withdraw_failed'), this.$t('min_wihtdrawa_amount') + ' : ' + this.methods[this.tabIndex].min + ' USDT');
                    return;

                }

            }
            if (this.$root.defaultCountry)
                var rate = this.$root.defaultCountry.buy;

            this.form.withdraw_amount_usdt = parseFloat(this.form.withdraw_amount_usdt);

            // this.amount_content.withdraw_amount = this.form.withdraw_amount_usdt * rate;
            // if (this.useOldWithdraw)
            //     this.amount_content.fee_usdt = this.methods[this.tabIndex].old_fee / 100 * this.form.withdraw_amount_usdt;
            // else
            this.amount_content.fee_usdt = this.methods[this.tabIndex].fee / 100 * this.form.withdraw_amount_usdt;
            // this.amount_content.fee = this.amount_content.fee_usdt * rate;
            this.amount_content.amount_received_usdt = this.form.withdraw_amount_usdt - this.amount_content.fee_usdt;
            this.amount_content.amount_received = this.amount_content.amount_received_usdt * rate;

            this.step = 2;
        },

        getRecord() {

            var self = this;
            self.$root.isLoading = true;
            var result = getWithdrawRecord();
            result
                .then(function (value) {
                    var rateInfo = value.data;
                    for (const key in rateInfo) {
                        const element = rateInfo[key];
                        switch (key) {
                            case 'bankfee':
                                self.methods[0].fee = parseFloat(element);
                                break;
                            case 'fee':
                                self.methods[1].fee = parseFloat(element);
                                break;
                            case 'masterCardfee':
                                self.methods[2].fee = parseFloat(element);
                                break;
                            case 'cashfee':
                                self.methods[3].fee = parseFloat(element);
                                break;
                        }

                    }
                    self.$root.isLoading = false;


                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // getRecord() {

        //     var self = this;
        //     self.$root.isLoading = true;
        //     var result = getWithdrawRecord();
        //     result
        //     .then(function (value) {
        //         var rateInfo = value.data;
        //         for (const key in rateInfo) {
        //             const element = rateInfo[key];
        //             switch (key) {
        //                 case 'bankfee':
        //                     self.methods[0].fee = parseFloat(element);
        //                     break;
        //                 case 'fee':
        //                     self.methods[1].fee = parseFloat(element);
        //                     break;
        //                 case 'masterCardfee':
        //                     self.methods[2].fee = parseFloat(element);
        //                     break;
        //                 case 'cashfee':
        //                     self.methods[3].fee = parseFloat(element);
        //                     break;
        //             }

        //         }
        //         self.$root.isLoading = false;


        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
        //     }
    },
    mounted() {
        if (this.$root.userInfo.check_kyc.can_withdraw == 'failed') {
            this.$root.makeToast("danger", this.$t('kyc_block_profile'), '');
            this.$router.push('/web/profile/kyc');
            setTimeout(() => this.$bvModal.hide('modal-withdraw'), 100);

            return;


        }
        this.getRecord();
        this.$root.getBankInfo().then(() => {
            const bankInfo = this.$root.bankInfo;
            if (bankInfo) {
                Object.keys(this.methods[0].params).forEach((key) => {
                    if (bankInfo[key] !== undefined) {
                        // Ensure reactivity by using Vue.set
                        this.$set(this.methods[0].params, key, bankInfo[key]);
                    }
                });
            }
        });
        var currentUserID = localStorage.getItem('userID');
        var blockList = [];
        var existUser = blockList.find((el) => currentUserID == el);

        if (existUser) {
            this.useOldWithdraw = true;
        }

        setTimeout(() => {
            this.$root.updatePb();
        }, 500);
        var val = this.$root.userInfo;
        for (let i = 0; i < this.methods.length; i++) {
            for (const formKey in this.methods[i].params) {
                for (const key in val) {
                    if (formKey == key) {
                        this.methods[i].params[key] = val[key];

                    } else if (formKey == ['address']) {
                        this.methods[i].params[formKey] = val['usdt_address'];

                    }
                }
            }
        }


        this.methods[3].params.fullname = val.cash_withdraw_fullname;
        this.methods[3].params.ic_number = val.cash_withdraw_ic;
        this.methods[3].params.contact_number = val.cash_withdraw_contact;

        this.$root.getCInfo(true);
        this.checkUI(this.tabIndex)

    },


}
</script>